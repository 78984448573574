import Altering from "../../Components/AlteringsSections/Altering_1/Altering.js";
import FigureBlock from "../../Components/FigureBlocks/FigureBlock_1/FigureBlock.js";
import Header from "../../Components/Headers/Header_1/Header.js";
import HeaderPage from "../../Components/LandingPages/LandingPage_3/LandingPage.js";
import Footer_1 from "../../Components/Footers/Footer_1/Footer.js";
import Footer_2 from "../../Components/Footers/Footer_2/Footer.js";
import Team1 from "../../Components/Team/Team_1/Team.js";
import ConnectionBlock_1 from "../../Components/ConnectionBlocks/ConnectionBlock_1/ConnectionBlock.js";
import BoxArray from "../../Components/General/BoxArray/BoxArray.js";
import TwoBlocks from "../../Components/General/TwoBlocks/TwoBlocks.js";
import MapsContainer from "../../Components/MapsContainers/MapsContainer_1/MapsContainer.js";
import ContactForm from "../../Components/ContactForms/ContactForm_1/ContactForm.js";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import img2 from "../../Images/img2.png";
// import img6 from "../../Images/img6.png";
import img7 from "../../Images/Roloo/DSC02046_1.jpg";
import { Helmet } from "react-helmet";


function Contact() {
    let openingHours = [
        <div className="TwoBlocks-Block-Content row">
            <div className="Content-Column1 col col-6">
                <p>Maandag-Vrijdag</p>
            </div>
            <div className="Content-Column2 col col-6">
                <p>7u00-19u00</p>
            </div>
        </div>,
        <div className="TwoBlocks-Block-Content row">
            <div className="Content-Column1 col col-6">
                <p>Zaterdag</p>
            </div>
            <div className="Content-Column2 col col-6">
                <p>7u00-12u00</p>
            </div>
        </div>,
        <div className="TwoBlocks-Block-Content row">
            <div className="Content-Column1 col col-6">
                <p>Zon- en feestdagen</p>
            </div>
            <div className="Content-Column2 col col-6">
                <p>Gesloten</p>
            </div>
        </div>,
        <div className="TwoBlocks-Block-Content row">
            <div className="Content-Column1 col col-6">
                <p>Bouwverlof</p>
            </div>
            <div className="Content-Column2 col col-6">
                <p>7u00-19u00</p>
            </div>
        </div>,
    ]

    let contactInfo = [
        <div className="TwoBlocks-Block-Content row">
            <div className="Content-Column1 col col-6">
                <p>Telefoon</p>
            </div>
            <div className="Content-Column2 col col-6">
                <a target="__blank" href="tel:+32476061270"><p>+32 476 06 12 70</p></a>
            </div>
        </div>,
        <div className="TwoBlocks-Block-Content row">
            <div className="Content-Column1 col col-6">
                <p>Email</p>
            </div>
            <div className="Content-Column2 col col-6">
            <a target="__blank" href="mailto:info@garageroloo.be"><p>info@garageroloo.be</p></a>
            </div>
        </div>,
        <div className="TwoBlocks-Block-Content row">
            <div className="Content-Column1 col col-6">
                <p>BTW</p>
            </div>
            <div className="Content-Column2 col col-6">
            <a target="__blank" href="https://kbopub.economie.fgov.be/kbopub/zoeknummerform.html?nummer=0789.608.704&actionLu=Zoek"><p>BE 0789.608.704</p></a>
            </div>
        </div>,
    ]

    return (
        <div className="Contact">
            <Helmet>
                <title>Roloo | Contact</title>
                <meta name="description" content="Voor al uw rollend materiaal! Roloo is een bedrijf dat gespecialiseerd is in het onderhouden en herstellen van vrachtwagens en machines. Wij staan steeds klaar voor onze klanten en bieden een snelle service aan. Heeft u vragen? Contacteer ons" />
                <meta name="keywords" content="Roloo, vrachtwagens, machines, onderhouden, herstellen, service, flexibiliteit, ervaring, kennis, tachografen, ijk, depannage, snelle service, ruime openingsuren, 24/7 bereikbaar, telefonisch, mail, samenwerking, merken, rolbruggen, heftrucks, garage" />
            </Helmet>
            <Header />
            <HeaderPage 
            title="Contact" 
            img={img7}/>
            <TwoBlocks
                title1="Openingsuren"
                content1={openingHours}
                title2="Contactgegevens"
                content2={contactInfo} />
            <MapsContainer 
                title="Vind ons achteraan op het terrein van Fransen J. Bvba"
                address="Schrieksesteenweg 65, 2580 Putte"
                link="https://goo.gl/maps/bTQjuZBy5QdS8fvr7"
            />
            <ContactForm />
            <ToastContainer/>
            <Footer_1 />
            <Footer_2 />
        </div>
    );
}

export default Contact;