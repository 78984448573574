import './Team.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons"
import imgTeam from '../../../Images/Roloo/DSC02145.jpg'
import imgTeam2 from '../../../Images/Roloo/DSC02068.jpg'
import imgYente from '../../../Images/Roloo/DSC02134.jpg'
import imgMathias from '../../../Images/Roloo/DSC02111.jpg'
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'

function Team(props) {
    return (
        <div className="Team_1">
            <div className="Team_1-Title">
                <div className="row">
                    <h2>Ons Team</h2>
                    <h5>
                        {props.subtitle}
                    </h5>
                </div>
            </div>
            <div className="Team_1-Content row">
                <div className="Team_1-Content-Image roundedCorners">
                    <div className='Team_1-Content-Image-Inner'>
                        <img src={imgTeam} alt="Team 1" />
                    </div>
                </div>
            </div>
            <div className="Team_1-Content row">
                <div className='col col-12 col-md-6'>
                    <div className="Team_1-Content-Member">
                        {/*A rectangular picture of the team member, below the persons name, below a short description of the person, last the social media icons that direct to linkedin page*/}
                        <div className="Team_1-Content-Member-Image skew roundedCorners">
                            <div className="Team_1-Content-Member-Image-Inner">
                                <img src={imgYente} alt="Team 1" />
                            </div>
                        </div>
                        <div className="Team_1-Content-Member-Name">
                            <h5>Yente Van Rompaye</h5>
                        </div>
                        <div className="Team_1-Content-Member-Description">
                            <p>Yente heeft reeds 12 jaar ervaring opgebouwd als mekanieker in de bouw- en tuinsector. Zijn uitgebreide technische kennis van machines maken hem de geknipte man voor elke herstelling.</p>
                        </div>
                        <div className="Team_1-Content-Member-SocialMedia">
                            <div className="Team-Social-Container">
                                <div className="Team-Social-Item">
                                    <a href='mailto:info@garageroloo.be' target='_blank' rel="noreferrer">
                                        <FontAwesomeIcon className='Team-Icon' icon={faEnvelope} />
                                    </a>
                                </div>
                                <div className="Team-Social-Item">
                                    <a href='tel:0476061270' target='_blank' rel="noreferrer">
                                        <FontAwesomeIcon className='Team-Icon' icon={faPhone} />
                                    </a>
                                </div>
                                {/* <div className="Team-Social-Item">
                                    <a href='https://www.facebook.com/' target='_blank' rel="noreferrer">
                                        <FontAwesomeIcon className='Team-Icon' icon={faFacebookSquare} />
                                    </a>
                                </div>
                                <div className="Team-Social-Item">
                                    <a href='https://www.instagram.com/' target='_blank' rel="noreferrer">
                                        <FontAwesomeIcon className='Team-Icon' icon={faInstagram} />
                                    </a>
                                </div>
                                <div className="Team-Social-Item">
                                    <a href='https://www.linkedin.com/' target='_blank' rel="noreferrer">
                                        <FontAwesomeIcon className='Team-Icon' icon={faLinkedin} />
                                    </a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col col-12 col-md-6'>
                    <div className="Team_1-Content-Member">
                        {/*A rectangular picture of the team member, below the persons name, below a short description of the person, last the social media icons that direct to linkedin page*/}
                        <div className="Team_1-Content-Member-Image skew roundedCorners">
                            <div className="Team_1-Content-Member-Image-Inner roundedCorners">
                                <img src={imgMathias} alt="Team 1" />
                            </div>
                        </div>
                        <div className="Team_1-Content-Member-Name">
                            <h5>Mathias Van Looy</h5>
                        </div>
                        <div className="Team_1-Content-Member-Description">
                            <p>Ook Mathias heeft reeds 12 jaar ervaring als mekanieker. Dit in de transportsector, wat maakt dat dit team elkaar perfect aanvult. Door zijn ervaring als mekanieker en als chauffeur, bezit hij een ruime kennis van vrachtwagens en trailers.</p>
                        </div>
                        <div className="Team_1-Content-Member-SocialMedia">
                            <div className="Team-Social-Container">
                                <div className="Team-Social-Item">
                                    <a href='mailto:info@garageroloo.be' target='_blank' rel="noreferrer">
                                        <FontAwesomeIcon className='Team-Icon' icon={faEnvelope} />
                                    </a>
                                </div>
                                <div className="Team-Social-Item">
                                    <a href='tel:0499353595' target='_blank' rel="noreferrer">
                                        <FontAwesomeIcon className='Team-Icon' icon={faPhone} />
                                    </a>
                                </div>
                                {/* <div className="Team-Social-Item">
                                    <a href='https://www.facebook.com/' target='_blank' rel="noreferrer">
                                        <FontAwesomeIcon className='Team-Icon' icon={faFacebookSquare} />
                                    </a>
                                </div>
                                <div className="Team-Social-Item">
                                    <a href='https://www.instagram.com/' target='_blank' rel="noreferrer">
                                        <FontAwesomeIcon className='Team-Icon' icon={faInstagram} />
                                    </a>
                                </div>
                                <div className="Team-Social-Item">
                                    <a href='https://www.linkedin.com/' target='_blank' rel="noreferrer">
                                        <FontAwesomeIcon className='Team-Icon' icon={faLinkedin} />
                                    </a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Team;