import './FigureBlock.css'

function FigureBlock(props) {

    return (
        <div className="FigureBlock_1">
            <div className="FigureBlock_1-Title">
                <h2>Enkele kerncijfers</h2>
                {/* <h5>Subtitle Figures</h5> */}
            </div>
            <div className="FigureBlock_1-Content">
                <div className="row">
                <div className="col col-12 col-md-4 FigureBlock_1-Item-Col">
                        <div className="FigureBlock_1-Item-Container skew roundedCorners">
                            <div className="FigureBlock_1-Item-Container-Number">
                                350
                            </div>
                            <div className="FigureBlock_1-Item-Container-Text">
                                <h4>Herstellingen per jaar</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col col-12 col-md-4 FigureBlock_1-Item-Col">
                        <div className="FigureBlock_1-Item-Container skew roundedCorners">
                            <div className="FigureBlock_1-Item-Container-Number">
                                24
                            </div>
                            <div className="FigureBlock_1-Item-Container-Text">
                                <h4>Jaar collectieve ervaring</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col col-12 col-md-4 FigureBlock_1-Item-Col">
                        <div className="FigureBlock_1-Item-Container skew roundedCorners">
                            <div className="FigureBlock_1-Item-Container-Number">
                                77
                            </div>
                            <div className="FigureBlock_1-Item-Container-Text">
                                <h4>Tevreden <br></br> klanten</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FigureBlock;

