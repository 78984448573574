import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home/Home.js';
import About from './Pages/About/About.js';
import Services from './Pages/Services/Services.js';
import Contact from './Pages/Contact/Contact.js';
import Privacy from './Pages/Privacy/Privacy.js';
import CookieConsent from "react-cookie-consent";
// import router from './Routes/Router.js';

import { analytics } from './firebase.js'
import {logEvent} from 'firebase/analytics'

logEvent(analytics, 'notification_received');


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route index path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </BrowserRouter>
    <CookieConsent
      // debug={true}
      location="bottom"
      buttonText="Accepteer"
      cookieName="_cookieConsent"
      style={{ background: "#222222" }}
      buttonStyle={{ color: "#FFFFFF", fontSize: "20px", borderRadius: "8px", background: "#D12710" }}
      expires={150}
    >
      Deze website gebruikt functionele en analytische cookies.{" "} Ontdek meer via onze <a href="/privacy" style={{textDecoration: "underline"}}>privacy policy</a>.
    </CookieConsent>
  </React.StrictMode>
);