import Menu from '../../Menus/Menu_1/Menu.js';
import './Header.css';
import logo from '../../../Images/Logo/logo.png';

function Header() {
  return (
    <div className="Header">
        <div className='Header-container'>
            <div className="Logo">
                <a href='/'><img src={logo} alt="Logo" /></a>
            </div>
            <div className="Menu">
                <Menu/>
            </div>
        </div>
    </div>
  );
}

export default Header;
