import React from "react";
import "./TwoBlocks.css";

function TwoBlocks(props) {
    return (
        <div className="TwoBlocks">
            <div className="row">
                <div className="col col-12 col-md-6">
                    <div className="TwoBlocks-Block">
                        <div className="TwoBlocks-Block-Title">
                            <h4>{props.title1}</h4>
                        </div>
                        <div className="TwoBlocks-Block-Content-Container">
                            <p>{props.content1}</p>
                        </div>
                    </div>
                </div>
                <div className="col col-12 col-md-6">
                    <div className="TwoBlocks-Block">
                        <div className="TwoBlocks-Block-Title">
                            <h4>{props.title2}</h4>
                        </div>
                        <div className="TwoBlocks-Block-Content-Container">
                            <p>{props.content2}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default TwoBlocks;