import React, { useState, useEffect } from 'react'
import Button from '../../Buttons/Button_1/Button.js';
import './LandingPage.css';
import BouncingArrow from '../../General/BouncingArrow_1/BouncingArrow.js';
import imgSite1 from '../../../Images/SITE-1.png';

function LandingPage(props) {
    const [screenSize, setScreenSize] = useState(getCurrentDimension());

    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);
        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])

    if (screenSize.width > 992) {
        return (
            <div className="LandingPage2">
                <div className="LandingPage2-Background">
                    <img src={imgSite1} alt="" />
                </div>
                <div className="LandingPage2-Overlay">
                </div>
                <div className="LandingPage2-Container">
                    <div className="row">
                        <div className="col col-12 col-lg-6">
                            <div className="LandingPage2-Text">
                                <h1>Voor al uw <span style={{ color: 'var(--color-main)' }}>Rollend</span> materiaal.</h1>
                                {/* <h4>Make contact with us!</h4> */}
                            </div>
                            <Button name={"Contact"} to={"/contact"} />
                        </div>
                        <div className="col col-12 col-lg-6">
                            <div className='LandingPage2-Double roundedCorners skew'></div>
                            <div className="LandingPage2-Image roundedCorners skew">
                                <div className='LandingPage2-Image-Inner'>
                                    <img src={props.img} alt="Image" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <BouncingArrow />
                </div>
            </div>
        );
    }
    else {
        return (
            <div className="LandingPage2">
                <div className="LandingPage2-Background">
                    <img src={imgSite1} alt="" />
                </div>
                <div className="LandingPage2-Overlay">
                </div>
                <div className="LandingPage2-Container">
                    <div className="row">
                        <div className="col col-12 col-lg-6 LandingPage2-Image-Col">
                            <div className='LandingPage2-Double roundedCorners skew'></div>
                            <div className="LandingPage2-Image roundedCorners skew">
                                <div className='LandingPage2-Image-Inner'>
                                    <img src={props.img} alt="Image" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col col-12 col-lg-6 LandingPage2-Text-Col">
                            <div className="LandingPage2-Text">
                                <h1>Voor al uw <span style={{ color: 'var(--color-main)' }}>Rollend</span> materiaal.</h1>
                                {/* <h4>Make contact with us!</h4> */}
                                <Button name={"Contact"} to={"/contact"} />
                            </div>
                        </div>
                    </div>
                    <BouncingArrow />
                </div>
            </div>
        );
    }
}

export default LandingPage;
