import './Menu.css';
import Button from '../../Buttons/Button_1/Button.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

function Menu() {
    const toggleMenu = () => {
        const menu = document.querySelector('.Menu-Items');
        const logo = document.querySelector('.Logo');
        menu.classList.toggle('Menu-Items-Active');
        logo.classList.toggle('Logo-Active');
    }
    return (
        <div className="Menu">
            <div className='Menu-Toggle' onClick={toggleMenu}>
                <FontAwesomeIcon className='Menu-Toggle-Bars' icon={faBars} />
            </div>
            <div className="Menu-Items ">
                {/* <div className="Menu-Item">
                    <a href="/"><h6>Home</h6></a>
                </div> */}
                <div className="Menu-Item">
                    <a href="/about"><h6>Over ons</h6></a>
                </div>
                <div className="Menu-Item">
                    <a href="/services"><h6>Diensten</h6></a>
                </div>
                <div className="Menu-Item">
                    <Button name={"Contact"} to={"/contact"} />
                </div>
            </div>
        </div>
    );
}

export default Menu;
