// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDuPAdbhvJ91ZrF7KIqKDmaHGNsfBdsxHI",
  authDomain: "roloo-4efb9.firebaseapp.com",
  projectId: "roloo-4efb9",
  storageBucket: "roloo-4efb9.appspot.com",
  messagingSenderId: "704038387272",
  appId: "1:704038387272:web:3a196f55eab43a4881e27d",
  measurementId: "G-LXC8C6M8Y4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
logEvent(analytics, 'notification_received');

export { analytics }