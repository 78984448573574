import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faFacebookF, faFacebookMessenger, faFacebookSquare, faInstagram, faInstagramSquare, faLinkedin, faSquareInstagram } from "@fortawesome/free-brands-svg-icons"
import './Footer.css';
import logo from '../../../Images/Logo/logo.png';

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <div className="Footer">
        <div className="row Footer-Main roundedCorners">
            <div className="col col-12 col-md-6 col-lg-4">
              <div className='Footer-Main-Title'><h3>Contact</h3></div>
              <div className='Footer-Main-Content'>
                <div className='Footer-Main-Content-Item'><h6>Roloo BV</h6></div>
                <div className='Footer-Main-Content-Item'><a target='_blank' href='https://goo.gl/maps/pf2vVSYJF1AGPNZ8A'><h6>Schrieksesteenweg 65</h6></a></div>
                <div className='Footer-Main-Content-Item'><a target='_blank' href='https://goo.gl/maps/pf2vVSYJF1AGPNZ8A'><h6>2580 Putte</h6></a></div>
                <div className='Footer-Main-Content-Item'><a href='tel:+32476061270'><h6>+32 476 06 12 70</h6></a></div>
                <div className='Footer-Main-Content-Item'><a target='_blank' href='mailto:info@garageroloo.be'><h6>info@garageroloo.be</h6></a></div>
                <div className='Footer-Main-Content-Item'><a target='_blank' href='https://kbopub.economie.fgov.be/kbopub/toonondernemingps.html?ondernemingsnummer=789608704'><h6>BE0789608704</h6></a></div>
              </div>
            </div>
            <div className="col col-12 col-md-6 col-lg-4">
              <div className='Footer-Main-Title'><h3>Navigatie</h3></div>
              <div className='Footer-Main-Content'>
                <div className='Footer-Main-Content-Item'><h6><a href='/'>Home</a></h6></div>
                <div className='Footer-Main-Content-Item'><h6><a href='/about'>Over ons</a></h6></div>
                <div className='Footer-Main-Content-Item'><h6><a href='/services'>Diensten</a></h6></div>
                <div className='Footer-Main-Content-Item'><h6><a href='/contact'>Contact</a></h6></div>
                <div className='Footer-Main-Content-Item'><h6><a href='/privacy'>Privacy verklaring</a></h6></div>
              </div>
            </div>
            <div className="col col-12 col-md-6 col-lg-4">
              <div className='Footer-Main-Title'><h3>Volg ons</h3></div>
              <div className='Footer-Main-Content'>
                <div className='Footer-Logo-Container'>
                  <img className='Footer-Logo' src={logo} alt="Logo" />
                </div>
                <div className="Footer-Social-Container">
                  <div className="Footer-Social-Item">
                    <a href='https://www.facebook.com/profile.php?id=100086718878830' target='_blank' rel="noreferrer">
                      <FontAwesomeIcon className='Footer-Icon' icon={faFacebookSquare} />
                    </a>
                  </div>
                  <div className="Footer-Social-Item">
                    <a href='https://www.instagram.com/garageroloo/' target='_blank' rel="noreferrer">
                      <FontAwesomeIcon className='Footer-Icon' icon={faInstagram} />
                    </a>
                  </div>
                  {/* <div className="Footer-Social-Item">
                    <a href='https://www.linkedin.com/' target='_blank' rel="noreferrer">
                      <FontAwesomeIcon className='Footer-Icon' icon={faLinkedin} />
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
        </div>
    </div>
  );
}

export default Footer;
