import './Button.css';

function Button(props) {
  return (
    <div className="Button skew">
        <h6><a href={props.to}>{props.name}</a></h6>
    </div>
  );
}

export default Button;
