import Button from '../../Buttons/Button_1/Button.js';
import BouncingArrow from '../../General/BouncingArrow_1/BouncingArrow.js';
import './LandingPage.css';

function LandingPage(props) {
  return (
    <div className="LandingPage3">
        <div className="LandingPage3-Background">
            <img src={props.img} alt="Background" />
        </div>
        <div className="LandingPage3-Overlay">
        </div>
        <div className="LandingPage3-Container">
            <h1 className="LandingPage3-Title">{props.title}</h1>
        </div>
    </div>
  );
}

export default LandingPage;
