import Altering from "../../Components/AlteringsSections/Altering_1/Altering.js";
import FigureBlock from "../../Components/FigureBlocks/FigureBlock_1/FigureBlock.js";
import Header from "../../Components/Headers/Header_1/Header.js";
import LandingPageAbout from "../../Components/LandingPages/LandingPage_3/LandingPage.js";
import Footer_1 from "../../Components/Footers/Footer_1/Footer.js";
import Footer_2 from "../../Components/Footers/Footer_2/Footer.js";
import Team1 from "../../Components/Team/Team_1/Team.js";
import ConnectionBlock_1 from "../../Components/ConnectionBlocks/ConnectionBlock_1/ConnectionBlock.js";
// import img2 from "../../Images/img2.png";
// import img3 from "../../Images/img3.png";
// import img6 from "../../Images/img6.png";
import img7 from "../../Images/Roloo/DSC02360_2.jpg";
import img4 from "../../Images/Roloo/DSC01804.jpg";
import img5 from "../../Images/Roloo/DSC02278.jpg";
import { Helmet } from "react-helmet";


function About() {
  const altering = {
    title: "Ontdek waar Roloo voor staat.",
    subtitle: "",
    content: [
      {
        title: "Onze missie",
        text: "Roloo staat voor kwaliteit! Wij proberen onze klanten zo goed mogelijk te ondersteunen bij de herstelling en het onderhoud van al hun rollend materiaal. Door ons geregeld bij te scholen en onze apparatuur te optimaliseren zijn we steeds mee met de nieuwste technologie.",
        img: img4
      },
      {
        title: "Onze visie",
        text: "Wij stellen alles in het werk om onze klant zo snel mogelijk verder te helpen. Dit doen we door ons flexibel op te stellen zodat een snelle service gegarandeerd wordt. Zo blijft het materiaal van onze klanten zo kort mogelijk stilstaan.",
        img: img5
      },
    ]
  }

  const team = {
    subtitle: "Het team van Garage Roloo bestaat uit twee gedreven jonge mannen die hun passie voor machines en vrachtwagens dag na dag uitoefenen. Al van kinds af zijn zij gepassioneerd bezig met het sleutelen aan en repareren van motoren. In 2022 besloten ze hun hobby om te vormen tot hun job en startten ze Garage Roloo. Hun passie voor het vak zorgt ervoor dat ze steeds op de hoogte zijn van de nieuwste technologieën en ontwikkelingen.",
  }

  return (
    <div className="About">
      <Helmet>
        <title>Roloo | Over Ons</title>
        <meta name="description" content="Voor al uw rollend materiaal! Garage Roloo is een jong en dynamisch bedrijf dat gespecialiseerd is in het onderhouden en herstellen van vrachtwagens en machines. Wij staan steeds klaar voor onze klanten en bieden een snelle service aan." />
        <meta name="keywords" content="Roloo, vrachtwagens, machines, onderhouden, herstellen, service, flexibiliteit, ervaring, kennis, tachografen, ijk, depannage, snelle service, ruime openingsuren, 24/7 bereikbaar, telefonisch, mail, samenwerking, merken, rolbruggen, heftrucks, garage" />
      </Helmet>
      <Header />
      <LandingPageAbout
        title="Over Ons"
        img={img7} />
      <Altering displayNumber={false} content={altering} reverse />
      <FigureBlock />
      <Team1 subtitle={team.subtitle} />
      <ConnectionBlock_1 />
      <Footer_1 />
      <Footer_2 />
    </div>
  );
}

export default About;