import './ConnectionBlock.css'
import React from 'react'
import Button from '../../Buttons/Button_1/Button.js'

function ConnectionBlock_1() {
    return (
        <div className="ConnectionBlock_1">
            <div className="ConnectionBlock_1-Container row roundedCorners">
                <div className="ConnectionBlock_1-Text">
                    <h1>Heeft u vragen? <br></br> Neem dan contact met ons op!</h1>
                </div>
                <div className="ConnectionBlock_1-Button">
                    <Button
                        className="ConnectionBlock_1-Contact-Button"
                        to="/contact"
                        name="Contact"
                    />
                </div>
            </div>
        </div>
    )
}

export default ConnectionBlock_1;