import React from "react";
import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import "./ContactForm.css";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";

const ContactForm = () => {
    const [status, setStatus] = useState("Submit");

    const showToastMessage = () => {
        if (status === "Submitted") {
            toast.success('Email successfully sent!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 5000,
            });

        } else {
            toast.error('Email failed to submit!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 5000,
            });
        }
    }

    const handleSubmit = async (values) => {
        setStatus("Sending...");
        const { name, email, company, subject, message } = values;
        console.log(values);
        fetch(process.env.REACT_APP_API_AWS_SES_LINK, {
            method: 'POST',
            mode: 'no-cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: name,
                email: email,
                company: company,
                message: message,
                sender: process.env.REACT_APP_API_AWS_SES_EMAIL_SENDER, // Change this depending on the client
                client: process.env.REACT_APP_API_AWS_SES_CLIENT_NAME, //Change this depending on the client
                subject: subject
            })
        }).then((response) => {
            setStatus("Submitted");
            console.log(response);
            document.getElementById("contactForm").reset();
        }).catch((error) => {
            console.log(error);
            setStatus("Failed to submit");
        });
        showToastMessage();
    };

    return (
        <Formik
            initialValues={{ name: "", email: "", company: "", subject: "", message: "" }}
            validate={(values) => {
                const errors = {};
                if (!values.name) {
                    errors.name = "Required";
                }
                if (!values.subject) {
                    errors.subject = "Required";
                }
                if (!values.email) {
                    errors.email = "Required";
                } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        values.email
                    )
                ) {
                    errors.email = "Invalid email address";
                }
                if (!values.message) {
                    errors.message = "Required";
                }
                return errors;
            }
            }
            onSubmit={handleSubmit}
        >
            {({ isSubmitting }) => (
                <Form id="contactForm" className="ContactForm skewReverse">
                    <div className="ContactForm-Title">
                        <h2>Contacteer ons</h2>
                    </div>
                    <div className="row ContactForm-Container">
                        <label htmlFor="name">Naam</label>
                        <Field className="Contact-Field skew roundedCorners" type="text" name="name" />
                        <ErrorMessage name="name" component="div" />
                        <label htmlFor="email">Email</label>
                        <Field className="Contact-Field skew roundedCorners" type="email" name="email" />
                        <ErrorMessage name="email" component="div" />
                        <label htmlFor="company">Bedrijf</label>
                        <Field className="Contact-Field skew roundedCorners" type="text" name="company" />
                        <ErrorMessage name="company" component="div" />
                        <label htmlFor="subject">Onderwerp</label>
                        <Field className="Contact-Field skew roundedCorners" type="text" name="subject" />
                        <ErrorMessage name="subject" component="div" />
                        <label htmlFor="message">Bericht</label>
                        <Field
                            className="Contact-Field roundedCorners"
                            component="textarea"
                            name="message"
                            placeholder=""
                        />
                        <ErrorMessage name="message" component="div" />
                        <button className="Contact-Button skew roundedCorners" type="submit" disabled={isSubmitting}>
                            <h6>Submit</h6>
                        </button>
                    </div>
                </Form>
            )
            }
        </Formik>
    );
};

export default ContactForm;