import React, { useState, useEffect } from 'react'
import './Altering.css';

function Altering(props) {
    let reverse = false;
    if (!props.reverse) {
        reverse = false;
    } else {
        reverse = true;
    }

    const [screenSize, setScreenSize] = useState(getCurrentDimension());

    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);
        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])

    function templateFull(arg1, arg2) {
        return (
            <div className="row Altering-Row">
                <div className="col col-12 col-lg-6 Altering-Row-Item-Col">
                    {arg1}
                </div>
                <div className="col col-12 col-lg-6 Altering-Row-Item-Col">
                    {arg2}
                </div>
            </div>
        )
    }

    function template(img, title, content, number) {
        let imgDiv =
            <div className="Altering-Row-Item-Col-Image skew roundedCorners">
                <div className='Altering-Row-Item-Col-Image-Inner'>
                    <img src={img} alt="Image" />
                </div>
            </div>


        let textDiv =
            <div className='Altering-Row-Item-Container'>
                <div className='Altering-Row-Item-Number' style={{display: props.displayNumber ? 'block' : 'none'}}>
                    {number}
                </div>
                <div className="Altering-Row-Item-Col-Text">
                    <div className="Altering-Row-Item-Col-Title">
                        <h3>{title}</h3>
                    </div>
                    <div className="Altering-Row-Item-Col-Content">
                        <p>{content}</p>
                    </div>
                </div>
            </div>
        return [imgDiv, textDiv]
    }


    function alteringDisplay(screenSize) {
        const rows = [];

        if (screenSize.width > 992) {
            for (let i = 0; i < props.content.content.length; i++) {
                let content = props.content.content[i];
                let [imgDiv, textDiv] = template(content.img, content.title, content.text, i + 1)

                if (reverse) {
                    [imgDiv, textDiv] = [textDiv, imgDiv]
                }

                if (i % 2 == 0) {
                    rows.push(templateFull(imgDiv, textDiv))
                } else {
                    rows.push(templateFull(textDiv, imgDiv))
                }
            }
        } else {
            for (let i = 0; i < props.content.content.length; i++) {
                let content = props.content.content[i];
                let [imgDiv, textDiv] = template(content.img, content.title, content.text, i + 1)
                rows.push(templateFull(textDiv, imgDiv))
            }
        }
        return rows;
    }

    return (
        <div className="Altering">
            <div className="Altering-Title">
                <h2>{props.content.title}</h2>
                <h5>{props.content.subtitle}</h5>
            </div>
            <div className="Altering-Content row">
                {alteringDisplay(screenSize)}
            </div>
        </div>
    )
};

export default Altering;