import Altering from '../../Components/AlteringsSections/Altering_1/Altering.js';
import CarouselSection from '../../Components/CarouselSections/CarouselSection_1/CarouselSection.js';
import ConnectionBlock_1 from '../../Components/ConnectionBlocks/ConnectionBlock_1/ConnectionBlock.js';
import Footer from '../../Components/Footers/Footer_1/Footer.js';
import Footer2 from '../../Components/Footers/Footer_2/Footer.js';
import Carousel from '../../Components/General/Carousels/Carousel_1/Carousel.js';
import Header from '../../Components/Headers/Header_1/Header.js';
import LandingPage from '../../Components/LandingPages/LandingPage_1/LandingPage.js';
import LandingPage2 from '../../Components/LandingPages/LandingPage_2/LandingPage.js';
import './Home.css';
// import img2 from "../../Images/img2.png";
// import img5 from "../../Images/img5.png";
import img4 from "../../Images/Roloo/DSC02012.jpg";
import img6 from "../../Images/Roloo/DSC01775-copy-0.jpg";
import img3 from '../../Images/Roloo/DSC02383.jpg';
import { FontAwesomeIcon, FaCheck } from '@fortawesome/react-fontawesome';
import { faChain, faCheck } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';


function Home() {
  const altering = {
    title: "Onze Troeven",
    subtitle: "Wij staan steeds klaar voor onze klanten! Ontdek al onze troeven hieronder.",
    content: [
      {
        title: "Flexibiliteit",
        text: <h5>
          <ul>
            <li><FontAwesomeIcon icon={faCheck} /> Ruime openingsuren</li>
            <li><FontAwesomeIcon icon={faCheck} /> 24/7 telefonisch en via mail bereikbaar</li>
            <li><FontAwesomeIcon icon={faCheck} /> Steeds bereikbaar voor (dringende) depannage</li>
            <li><FontAwesomeIcon icon={faCheck} /> Snelle service</li>
          </ul>
        </h5>,
        img: img4
      },
      {
        title: "Ervaring en kennis",
        text: <h5>
          <ul>
            <li><FontAwesomeIcon icon={faCheck} /> Vrachtwagens en machines van A tot Z</li>
            <li><FontAwesomeIcon icon={faCheck} /> Herstellen van alle merken</li>
            <li><FontAwesomeIcon icon={faCheck} /> IJkingen en herstellingen van tachografen</li>
          </ul>
        </h5>,
        img: img3
      },
      // {
      //   title: "Onderhouden",
      //   text: "Een blijvende samenwerking is voor ons zeer belangrijk. Daarom bieden wij ook aan om uw vrachtwagen of machine geregeld te onderhouden.",
      //   img: img5
      // },
    ]
  }



  return (
    <div className="Home">
      <Helmet>
        <title>Roloo | Home</title>
        <meta name="description" content="Voor al uw rollend materiaal! Roloo is een bedrijf dat gespecialiseerd is in het onderhouden en herstellen van vrachtwagens en machines. Wij staan steeds klaar voor onze klanten en bieden een snelle service aan." />
        <meta name="keywords" content="Roloo, vrachtwagens, machines, onderhouden, herstellen, service, flexibiliteit, ervaring, kennis, tachografen, ijk, depannage, snelle service, ruime openingsuren, 24/7 bereikbaar, telefonisch, mail, samenwerking, merken, rolbruggen, heftrucks, garage" />
      </Helmet>
      <Header />
      {/* <LandingPage /> */}
      <LandingPage2 img={img6} />
      {/* <CarouselSection /> */}
      <Altering
        content={altering} />
      <ConnectionBlock_1 />
      <Footer />
      <Footer2 />
    </div>
  );
}

export default Home;
