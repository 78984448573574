import React from 'react';
import Header from '../../Components/Headers/Header_1/Header.js';
import HeaderPage from '../../Components/LandingPages/LandingPage_3/LandingPage.js';
import Footer from '../../Components/Footers/Footer_1/Footer.js';
import Footer2 from '../../Components/Footers/Footer_2/Footer.js';
import img12 from '../../Images/Roloo/DSC02456_1.jpg';
import './Privacy.css';


function Privacy() {
    return (
        <div className="Privacy">
            <Header />
            <HeaderPage
                title="Privacy"
                img={img12}
            />
            <div className="container">
                <div className="row">
                    <div className="col col-12">
                        <h1>Privacy Policy</h1>
                        <ol>
                            <h2><li>Algemeen</li></h2>
                            <p>
                                Deze Privacyverklaring regelt de verwerking van uw persoonsgegevens als een onderdeel van het gebruik van onze website (<a href='https://www.garageroloo.be'>www.garageroloo.be</a>) en de diensten die daarmee verband houden. Deze verwerkingen worden steeds uitgevoerd door ROLOO BV als verwerkingsverantwoordelijke, gevestigd te Schrieksesteenweg 65, 2580 Putte, met ondernemingsnummer BE789608704, hierna aangeduid als “Bedrijf”, "Wij", "Roloo", "Ons".
                            </p>
                            <p>
                                Het beschermen van persoonsgegevens van klanten en bezoekers is erg belangrijk voor ons. Daarom stellen we alles in het werk op deze gegevensbescherming te waarborgen en steeds conform de General Data Protection Regulation (GDPR) te handelen wanneer wij uw persoonsgegevens verwerken.
                            </p>
                            <p>
                                Meer concreet zal Roloo de persoonsgegevens steeds rechtmatig behandelen voor welbepaalde doeleinden. Wij zullen het nodige doen om nooit meer persoonsgegevens te vragen dan noodzakelijk voor het doel of om deze persoonsgegevens langer dan nodig te bewaren. Tot slot neemt Roloo de technische en organisatorische maatregelen die een passende beveiliging waarborgen zodat persoonsgegevens worden beveiligd.
                            </p>
                            <p>
                                Deze Privacyverklaring was laatst bijgewerkt op 24 Augustus 2023. Roloo behoudt zich het recht om deze Privacyverklaring naar aan te passen. Wijzigingen zullen steeds worden gecommuniceerd.
                            </p>
                            <h2><li>Bedrijf</li></h2>
                            <p>
                                Roloo is gespecialiseerd in het herstellen en onderhouden van vrachtwagens, opleggers, aanhangwagens, bestelwagens, machines, ... Onze activiteiten omvatten:
                                <ul>
                                    <p>
                                        <li>
                                            Herstellingen van mechanische en elektrische defecten aan vrachtwagens, opleggers, aanhangwagens, bestelwagens, machines, ...
                                        </li>
                                        <li>
                                            Algemeen onderhoud van gemotoriseerde voertuigen en machines en het uitvoeren van de nodige keuringen.
                                        </li>
                                        <li>
                                            Algemeen onderhoud van opleggers en aanhangwagens en niet-gemotoriseerde machines.
                                        </li>
                                        <li>
                                            Verkoop van onderdelen en accessoires voor gemotoriseerde voertuigen en machines.
                                        </li>
                                    </p>
                                </ul>
                                In die context komt Roloo in contact met persoonsgegevens, zowel van onze eigen medewerkers, als van onze klanten. Roloo is verantwoordelijk voor deze gegevens, en om daar mee om te gaan op een manier die overeenkomt met de geldende wettelijke eisen.
                            </p>
                            <h2><li>Persoonsgegevens</li></h2>
                            <p>
                                Persoonsgegevens zijn alle gegevens die betrekking hebben op een geïdentificeerde of identificeerbare natuurlijke persoon. Dit zijn dus alle gegevens die direct of indirect te herleiden zijn naar een persoon. Voorbeelden hiervan zijn naam, adres, telefoonnummer, e-mailadres, IP-adres, geboortedatum, ...
                            </p>
                            <p>De gegevens die wij verzamelen, zijn:
                                <ul>
                                    <li>
                                        Naam
                                    </li>
                                    <li>
                                        Voornaam
                                    </li>
                                    <li>
                                        Emailadres
                                    </li>
                                    <li>
                                        Telefoonnummer
                                    </li>
                                </ul>
                            </p>
                            <p>
                                Roloo verwerkt persoonsgegevens van klanten en bezoekers van de website. Deze persoonsgegevens worden verwerkt voor de volgende doeleinden:
                                <ul>
                                    <li>
                                        Het uitvoeren van de overeenkomst die u met ons heeft gesloten.
                                    </li>
                                    <li>
                                        Facturatie en boekhouding.
                                    </li>
                                    <li>
                                        Het beheren van de klantenadministratie.
                                    </li>
                                    <li>
                                        Communicatie in het kader van de overeenkomst en de professionele relatie.
                                    </li>
                                </ul>
                            </p>
                            <p>
                                Roloo geeft geen persoonsgegevens door aan derde partijen, tenzij dit noodzakelijk is voor de uitvoering van de doeleinden waarvoor ze zijn verstrekt. In dat geval worden de gegevens enkel gebruikt voor het doel waarvoor ze zijn verstrekt.
                            </p>
                            <h2><li>Welke rechten u heeft, en hoe u ze kan uitoefenen</li></h2>
                            <p>
                                U heeft het recht om uw persoonsgegevens in te zien, te corrigeren of te verwijderen. Daarnaast heeft u het recht om uw eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking van uw persoonsgegevens door Roloo en heeft u het recht op gegevensoverdraagbaarheid. Dat betekent dat u bij ons een verzoek kan indienen om de persoonsgegevens die wij van u hebben in een computerbestand naar u of een ander, door u genoemde organisatie, te sturen.
                            </p>
                            <p>
                                Indien u vragen heeft over de verwerking van uw persoonsgegevens of indien u een verzoek tot inzage, correctie, verwijdering, gegevensoverdracht van uw persoonsgegevens of verzoek tot intrekking van uw toestemming of bezwaar op de verwerking van uw persoonsgegevens wenst te doen, kan u contact opnemen met ons via <a href="mailto:info@garageroloo.be">info@garageroloo.be</a>
                            </p>
                            <p>
                                Indien u vindt dat Roloo uw persoonsgegevens onrechtmatig verwerkt, kan u een klacht indienen bij de Gegevensbeschermingsautoriteit (GBA) <a href='https://www.gegevensbeschermingsautoriteit.be'>www.gegevensbeschermingsautoriteit.be</a>.
                            </p>
                            <h2><li>Cookies</li></h2>
                            <p>
                                Roloo gebruikt functionele en analytische cookies. Een cookie is een klein tekstbestand dat bij het eerste bezoek aan deze website wordt opgeslagen in de browser van uw computer, tablet of smartphone. 
                            </p>
                            <p>
                                Roloo gebruikt enerzijds functionele cookies. Deze zorgen ervoor dat de website naar behoren werkt, kunnen bijvoorbeeld uw voorkeursinstellingen onthouden worden en helpen ons om de website goed te laten werken. Ook kunnen wij hiermee onze website optimaliseren. 
                            </p>
                            <p>    
                                Daarnaast gebruikt Roloo cookies ook analytische cookies. Deze geven ons inzicht in wie de website gebruikt. Deze informatie wordt geanonimiseerd en wordt niet gebruikt voor andere doeleinden dan het verbeteren van de website. 
                            </p>
                            <p>
                                Tot slot gebruikt Roloo geen tracking cookies.
                            </p>
                            <p>
                                Een lijst van alle cookies die wij gebruiken kan u hier vinden:
                                <table>
                                    <tr>
                                        <th>Naam</th>
                                        <th>Maker</th>
                                        <th>Persoons-gegevens</th>
                                        <th>Doel</th>
                                        <th>Type</th>
                                        <th>Vervaltermijn</th>
                                    </tr>
                                    <tr>
                                        <td>Google Analytics</td>
                                        <td>Google Analytics</td>
                                        <td>Ja</td>
                                        <td>Deze cookie wordt gebruikt om anoniem informatie te verzamelen over hoe bezoekers onze website gebruiken.</td>
                                        <td>NF</td>
                                        <td>2 jaar</td>
                                    </tr>
                                </table>
                            </p>
                        </ol>
                    </div>
                </div>
            </div>
            <Footer/>
            <Footer2/>
        </div>
    );
}

export default Privacy;