import './BouncingArrow.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown } from '@fortawesome/free-solid-svg-icons'

function link(props) {
    if (props.link !== undefined){
        return props.link
    }
    else{
        return "#"
    }
}

function BouncingArrow(props) {
  return (
    <div className="BouncingArrow">
        <div class="arrow bounce">
            <a class="" href={link(props)}>
                <FontAwesomeIcon icon={faArrowDown} size='2x'/>
            </a>
        </div>
    </div>
  );
}

export default BouncingArrow;