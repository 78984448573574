import React from "react";
import "./MapsContainer.css";
import Map from "../../Maps/Map_1/Map.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import parking from "../../../Images/Garage_Parking.png";

function MapsContainer(props) {
    return (
        <div className="MapsContainer">
            <div className="MapsContainer-Title">
                <h4>{props.title}</h4>
                <a href={props.link} target="__blank"><h5> <FontAwesomeIcon icon={faUpRightFromSquare} /> {props.address}</h5></a>
            </div>
            <div className="MapsContainer-Content-Container row">
                <div className="col col-12 col-md-6">
                    <div className="MapsContainer-Content roundedCorners">
                        <Map/>
                    </div>
                </div>
                <div className="col col-12 col-md-6">
                    <div className="MapsContainer-Content roundedCorners">
                        <img src={parking}></img>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MapsContainer;