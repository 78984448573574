import React from 'react';
import './BoxArray.css';

function BoxArray(props) {
    function box(img, title, description) {
        return (
        <div className='col col-12 col-md-6 col-lg-4'>
            <div className='Box skew roundedCorners'>
                <div className='Box-Image'>
                    <img src={img} alt='Box' />
                </div>
                <div className='Box-Image-Overlay'></div>
                <div className='Box-Text'>
                    <div className='Box-Text-Title'>
                        <h3>{title}</h3>
                        {/* <h3>Hello World</h3> */}
                    </div>
                    <div className='Box-Text-Description'>
                        <p>{description}</p>
                        {/* <p>Lorum ipsum </p> */}
                    </div>
                </div>
            </div>
        </div>
        )
    };

    let boxes = []
    for (let i = 0; i < props.imgs.length; i++) {
        // boxes.push(box(props.imgs[i]), props.titles[i], props.descriptions[i])
        boxes.push(box(props.imgs[i], props.titles[i], props.descriptions[i]))
    }

    return (
        <div className="BoxArray">
            <div className="BoxArray-Title row">
                <h2>{props.title}</h2>
                <h5>{props.subtitle}</h5>
            </div>
            <div className='BoxArray-Content row'>
                {boxes}
            </div>
        </div>
    );
}

export default BoxArray;