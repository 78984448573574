import Altering from "../../Components/AlteringsSections/Altering_1/Altering.js";
import FigureBlock from "../../Components/FigureBlocks/FigureBlock_1/FigureBlock.js";
import Header from "../../Components/Headers/Header_1/Header.js";
import HeaderPage from "../../Components/LandingPages/LandingPage_3/LandingPage.js";
import Footer_1 from "../../Components/Footers/Footer_1/Footer.js";
import Footer_2 from "../../Components/Footers/Footer_2/Footer.js";
import Team1 from "../../Components/Team/Team_1/Team.js";
import ConnectionBlock_1 from "../../Components/ConnectionBlocks/ConnectionBlock_1/ConnectionBlock.js";
import BoxArray from "../../Components/General/BoxArray/BoxArray.js";
// import imgHeader from "../../Images/img3.png";
import img1 from "../../Images/Roloo/DSC02145.jpg";
import img2 from "../../Images/Roloo/DSC02177.jpg";
import img3 from "../../Images/Roloo/DSC01945.jpg";
import img4 from "../../Images/Roloo/DSC02323.jpg";
import img5 from "../../Images/Roloo/DSC01861_1.jpg";
import img6 from "../../Images/Roloo/DSC01928_1.jpg";
import img7 from "../../Images/Roloo/DSC01928.jpg";
import img8 from "../../Images/Roloo/DSC01928.jpg";
import img9 from "../../Images/Roloo/DSC01928.jpg";
import img10 from "../../Images/Roloo/DSC02278.jpg";
import img11 from "../../Images/Roloo/DSC02012.jpg";
import img12 from "../../Images/Roloo/DSC02456_1.jpg";
import img13 from "../../Images/Roloo/DSC02217.jpg";
import { Helmet } from "react-helmet";

function Services() {
    return (
        <div className="Services">
            <Helmet>
                <title>Roloo | Diensten</title>
                <meta name="description" content="Voor al uw rollend materiaal! Roloo is een bedrijf dat gespecialiseerd is in het onderhouden en herstellen van vrachtwagens en machines. Wij staan steeds klaar voor onze klanten en bieden een snelle service aan." />
                <meta name="keywords" content="Roloo, vrachtwagens, machines, onderhouden, herstellen, service, flexibiliteit, ervaring, kennis, tachografen, ijk, depannage, snelle service, ruime openingsuren, 24/7 bereikbaar, telefonisch, mail, samenwerking, merken, rolbruggen, heftrucks, garage" />
            </Helmet>
            <Header />
            <HeaderPage
                title="Diensten"
                img={img12}
            />
            <BoxArray
                title={"Onze diensten"}
                subtitle={"Bij Roloo helpen we onze klanten met een ruim aanbod aan diensten. Van vrachtwagen tot generator, van oplegger tot graafmachine, ... Wij herstellen het allemaal."}
                imgs={[
                    img4,
                    img6,
                    img13,
                    img10,
                    img5,
                    img2,
                    img6,
                    img3,
                    img11
                ]}
                titles={[
                    "Herstellingen",
                    "Onderhoud",
                    "Keuringen",
                    "Trailer",
                    "Remmen",
                    "Tachoijkingen",
                    "Diagnose",
                    "Airco",
                    "Machine-onderhoud",
                ]}
                descriptions={[
                    "Wij herstellen alle merken en types van vrachtwagens, opleggers, aanhangwagens, bestelwagens, machines, ...",
                    "Bij Roloo kan u terecht voor het onderhoud van al uw rollend materiaal.",
                    "Wij laten uw voertuig graag keuren voor u. Breng het langs en wij zorgen voor de rest.",
                    "Iets mis met uw oplegger? Breng het binnen en wij zorgen dat u snel terug op de baan bent.",
                    "Met onze remtester, zorgen we ervoor dat uw voertuig steeds in orde en veilig is.",
                    "Met onze aangepaste aparatuur ijken wij uw tachograaf zodat u steeds in orde bent met de wetgeving.",
                    "Met onze diagnose apparatuur kunnen wij snel en efficiënt de fouten van uw voertuig opsporen.",
                    "Wij zorgen ervoor dat uw airco steeds in orde is zodat u steeds in een frisse cabine kan rijden.",
                    "Ook voor het onderhoud van uw machines kan u bij ons terecht."
                ]}
            />
            <ConnectionBlock_1 />
            <Footer_1 />
            <Footer_2 />
        </div>
    );
}

export default Services;