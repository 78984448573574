import Button from '../../Buttons/Button_1/Button.js';
import BouncingArrow from '../../General/BouncingArrow_1/BouncingArrow.js';
import './LandingPage.css';

function LandingPage() {
  return (
    <div className="LandingPage">
        <div className="LandingPage-Background">
            <img src="https://via.placeholder.com/1000" alt="Background" />
        </div>
        <div className="LandingPage-Overlay">
        </div>
        <div className="LandingPage-Container">
            <h1 className="LandingPage-Title">Landing Page</h1>
            <Button name={"Contact"} to={"#"} />
            <BouncingArrow link={"#"}/>
        </div>
    </div>
  );
}

export default LandingPage;
