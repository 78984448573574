import './Footer.css';


function Footer() {
    const currentYear = new Date().getFullYear();
    return (
        <div className="Footer_2">
            <div className="row Footer_2-Main">
                <div className="row Footer_2-Sub">
                    <div className='col col-12'><a href='/privacy' style={{textDecoration: "underline"}}>Privacy verklaring</a></div>
                    <div className='col col-12'>{currentYear} &copy; Copyright Roloo BV</div>
                    <div className='col col-12'>Website created and hosted by <a target='_blank' href='https://thecodingtree.be'>TheCodingTree</a></div>
                </div>
            </div>
        </div>
    );
}

export default Footer;