import React from "react";
import "./Map.css";

function Map(props) {
    let width = "100%";
    let height = "400";
    return (
        <div className="Map roundedCorners">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1625.0138914838517!2d4.644576743268098!3d51.04267004335142!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c159b3d27d1095%3A0x7e14f3304ce00c4d!2sGarage%20Roloo!5e1!3m2!1snl!2sbe!4v1691054798842!5m2!1snl!2sbe" width={width} height={height} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>

    );
}

export default Map;